import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { navigate } from "gatsby-plugin-intl";

const Test = () => {
	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="test">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title="Test" />
					<main className="page">
						Test
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

export default Test;
